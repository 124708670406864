<div class="text-right pr-8">
    <ul class="inline-flex flex-col items-end gap-y-4">
        <li *ngFor="let item of menu">
            <a [routerLink]="[item.route]" routerLinkActive="text-secondary active">
                {{ item.name | translate }}
            </a>
        </li>

        <div class="inline-flex flex-col items-end gap-y-4 pt-8">
            <ng-container *ngIf="features$ | async as features">
                <li *ngIf="features.includes('complaints.list')">
                    <a routerLink="/complaints" translate>header.profile.popover.complaints</a>
                </li>
            </ng-container>

            <li>
                <a href="#" (click)="onProfileClick($event)" translate>btn.profile</a>
            </li>
        </div>

        <li *ngIf="(userSwitchEnabled$ | async) === true">
            <a href="#" (click)="onUserSwitchClick($event)" translate>btn.switch-user</a>
        </li>

        <li *ngIf="(userSwitchEnabled$ | async) === false">
            <a href="#" (click)="onLogoutClick($event)" translate>btn.logout</a>
        </li>
    </ul>
</div>
