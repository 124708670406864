<div class="grid lg:flex gap-10 text-sm w-full">
    <div class="md:col-span-3 md:row-start-2 lg:row-start-1 lg:col-span-1 max-w-md md:min-w-[18rem]">
        <h2 class="text-lg font-bold mb-5">
            {{ 'footer.contact.title' | translate }}
        </h2>

        <div class="grid gap-1">
            <ng-container *ngFor="let item of contact">
                <a *ngIf="item.href" [href]="item.href">
                    {{ item.translate.content && item.content ? ('footer.contact.' + item.content | translate) : item.content }}
                </a>

                <span *ngIf="!item.href">
                    <strong>{{ item.translate.heading && item.heading ? ('footer.contact.' + item.heading | translate) : item.heading }}</strong>
                    {{ item.translate.content && item.content ? ('footer.contact.' + item.content | translate) : item.content }}
                </span>
            </ng-container>
        </div>
    </div>

    <div class="max-w-lg">
        <h2 class="text-lg font-bold mb-5">{{ 'footer.shop' | translate }}</h2>

        <div class="grid gap-3">
            <p class="whitespace-pre-line">
                {{ 'footer.description' | translate }}
            </p>

            <a *ngFor="let link of shopLinks" [href]="link.href" target="_blank">{{ link.name }}</a>
        </div>
    </div>

    <div class="flex flex-col lg:ml-auto">
        <h2 class="text-lg font-bold mb-5">
            {{ 'footer.links.title' | translate }}
        </h2>

        <ul class="grid gap-2">
            @for (link of footerLinks$ | async; let index = $index; track index) {
                <li class="flex gap-1 items-center">
                    <app-icon class="!leading-4 text-md" name="material-symbols:chevron-right-rounded"></app-icon>
                    @if (link.href.startsWith('http')) {
                        <a [href]="link.href" target="_blank">
                            {{ 'footer.links.' + link.translationKey | translate }}
                        </a>
                    } @else {
                        <a [routerLink]="link.href">
                            {{ 'footer.links.' + link.translationKey | translate }}
                        </a>
                    }
                </li>
            }
        </ul>

        <div class="mt-auto hidden lg:block">
            <app-social-icons />
        </div>
    </div>

    <div class="md:row-start-3 lg:hidden">
        <app-social-icons />
    </div>
</div>
