@if (footer.title) {
    <h2 class="text-lg font-bold mb-10">
        {{ 'footer.contact.title' | translate }}
    </h2>
}

@if (footer.logo) {
    <img [class.mx-auto]="footer.center" class="block min-w-[4rem] max-w-[4rem] sm:min-w-[5rem] sm:max-w-[5rem] w-21 mb-3" [src]="logoSrc" />
}

<div class="grid-container-fill | grid gap-x-5 gap-y-14" [class.text-center]="footer.center">
    @for (item of footer.addresses; let index = $index; track index) {
        <div class="flex flex-col gap-y-3.5 justify-between text-white/95">
            @if (item.name) {
                <h3 class="w-max uppercase inline-block border-b px-1.5 -ml-1.5">{{ item.name }}</h3>
            }

            @if (item.address && item.address.length > 0) {
                <div class="text-sm">
                    @for (address of item.address; let index = $index; track index) {
                        <div>{{ address }}</div>
                    }
                </div>
            }

            @if (item.additional && item.additional.length > 0) {
                <div class="text-sm">
                    @for (info of item.additional; let index = $index; track index) {
                        <div>{{ info }}</div>
                    }
                </div>
            }

            @if (item.googleMaps) {
                <a
                    class="mt-auto border border-white py-1.5 px-2.5 w-max inline-flex items-center gap-1.5 hover:bg-white/10"
                    [href]="item.googleMaps"
                    target="_blank"
                >
                    <span class="text-sm">{{ 'footer.open-map' | translate }}</span>
                    <app-icon class="text-md" name="material-symbols:location-on" />
                </a>
            }
        </div>
    }
</div>
