import { Component, inject } from '@angular/core';
import { Grow } from '@library';
import { ConcessionsFacade } from '@app/store/concessions/facades/concessions.facade';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-auth-concessions',
    templateUrl: './auth-concessions.component.html',
    styleUrls: ['./auth-concessions.component.scss'],
    animations: [Grow],
})
export class AuthConcessionsComponent {
    private readonly concessionsFacade = inject(ConcessionsFacade);
    readonly authConcessions$ = this.concessionsFacade.authEndsConcessions$;

    show$ = new BehaviorSubject<boolean>(false);

    toggle() {
        this.show$.next(!this.show$.value);
    }
}
