import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { options } from '@options';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    private router = inject(Router);

    readonly logoSrc = options.logoSrc.dark;

    onLogoutClick() {
        this.router.navigate(['/logout'], {
            skipLocationChange: true,
        });
    }
}
