import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { of, tap } from 'rxjs';
import { environment } from '@env';

interface CacheValue {
    response: HttpResponse<unknown>;
    timestamp: number;
}

@Injectable({
    providedIn: 'root',
})
export class CacheInterceptor implements HttpInterceptor {
    private cache: Map<string, CacheValue> = new Map();

    intercept(req: HttpRequest<unknown>, next: HttpHandler) {
        if (req.method !== 'GET') {
            return next.handle(req);
        }

        const cachedResponse = this.cache.get(req.urlWithParams);
        const cacheDurationInMilliseconds = environment.cacheDurationInMinutes * 60 * 1000;
        const cacheExpired = !!cachedResponse && Date.now() - cachedResponse.timestamp > cacheDurationInMilliseconds;
        if (cachedResponse && !cacheExpired) {
            return of(cachedResponse.response.clone());
        }

        return next.handle(req).pipe(
            tap((stateEvent) => {
                if (stateEvent instanceof HttpResponse) {
                    this.cache.set(req.urlWithParams, { response: stateEvent.clone(), timestamp: Date.now() });
                }
            }),
        );
    }
}
