<header class="border-b border-gray-100 w-full bg-white sticky top-0">
    <div class="mx-auto flex items-stretch">
        <div class="py-[1.625rem] px-8 sm:px-[4.5rem] border-r border-gray-100">
            <a routerLink="customers">
                <img class="block min-w-[5rem] max-w-[5rem] sm:min-w-[7rem] sm:max-w-[7rem]" [src]="logoSrc" alt="Logo" />
            </a>
        </div>

        <div class="ml-auto flex items-center">
            <div class="mr-6 sm:mr-8">
                <app-language-switch></app-language-switch>
            </div>

            <div class="px-6 border-l border-gray-100 | md:px-8">
                <app-user-actions></app-user-actions>
            </div>
        </div>

        <div class="self-center pr-8 text">
            <button type="button" class="block" (click)="onLogoutClick()">
                <app-icon name="material-symbols:logout-rounded" class="block text-md text-gray-700"></app-icon>
            </button>
        </div>
    </div>
</header>
