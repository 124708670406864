import { Component } from '@angular/core';
import { options } from '@options';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    readonly logoSrc = options.logoSrc.dark;
}
