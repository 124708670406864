import { Component, inject } from '@angular/core';
import { RoleService } from '@app/shared/services';
import { UserFacade } from '@app/store/user';
import { combineLatest, map, startWith } from 'rxjs';

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss'],
})
export class ShellUserComponent {
    private userFacade = inject(UserFacade);
    private roleService = inject(RoleService);

    private isSalesperson$ = this.roleService.isSalesperson$.pipe(startWith(null));
    private salespersonInfo$ = this.userFacade.user$.pipe(
        startWith(null),
        map((user) => user?.salespersonInfo),
    );

    readonly data$ = combineLatest({ salespersonInfo: this.salespersonInfo$, isSalesperson: this.isSalesperson$ });
}
