<ng-container *ngIf="data$ | async as data">
    <div class="min-h-screen flex flex-col relative">
        <div class="sticky top-0 z-[100]">
            <app-header />
        </div>

        <p
            *ngIf="data.isSalesperson && data.salespersonInfo && data.salespersonInfo.no && data.salespersonInfo.name"
            class="text-eip-green font-semibold text-sm ml-6 md:ml-[4.25rem] pt-3.5"
        >
            {{ data.salespersonInfo.no + ' ' + data.salespersonInfo.name }}
        </p>

        <section
            class="grow py-[3.25rem] mx-5 | 3xl:container 3xl:mx-auto"
            [ngClass]="data.isSalesperson && data.salespersonInfo ? 'pt-[1.125rem]' : 'pt-[3.25rem]'"
        >
            <app-statuses-container>
                <app-payment-concessions />
                <app-auth-concessions />
                <app-ship />
            </app-statuses-container>
            <router-outlet />
        </section>

        <app-footer />
    </div>
</ng-container>
