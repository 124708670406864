import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { CONCESSIONS_FEATURE_KEY } from './keys';
import { concessionsReducer } from './reducers/concessions.reducer';
import { ConcessionsEffects } from './effects/concessions.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(CONCESSIONS_FEATURE_KEY, concessionsReducer), EffectsModule.forFeature(ConcessionsEffects)],
})
export class ConcessionsStoreModule {}
