import { createReducer, on } from '@ngrx/store';
import { Concession } from '../models';
import { concessionsActions } from '../actions/concessions.actions';

export interface ConcessionsState {
    concessions: Concession[] | null;
}

export const initialState: ConcessionsState = {
    concessions: null,
};

export const concessionsReducer = createReducer(
    initialState,

    on(concessionsActions.getConcessions, (state): ConcessionsState => {
        return {
            ...state,
            concessions: [],
        };
    }),

    on(concessionsActions.getConcessionsSuccess, (state, payload): ConcessionsState => {
        return {
            ...state,
            concessions: payload.items,
        };
    }),

    on(concessionsActions.getConcessionsError, (state): ConcessionsState => {
        return {
            ...state,
            concessions: null,
        };
    }),

    on(concessionsActions.clear, (): ConcessionsState => {
        return {
            ...initialState,
        };
    }),
);
