export const footer = {
    socialLinks: [
        {
            href: 'https://www.facebook.com/groups/akademiamillesapori',
            iconName: 'mdi:facebook',
            style: 'background-color: #4267B2;',
        },
        {
            href: 'https://www.instagram.com/millesaporiplus',
            iconName: 'mdi:instagram',
            style: 'background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);',
        },
        {
            href: 'https://www.youtube.com/channel/UCvF4GAi8CiSWFNGgJLcf2Ww',
            iconName: 'entypo-social:youtube-with-circle',
            style: 'background-color: #FF0000;',
        },
        {
            href: 'https://www.linkedin.com/company/millesaporiplus',
            iconName: 'ri:linkedin-fill',
            style: 'background-color: #0077b5;',
        },
        {
            href: 'https://www.millesapori.pl',
            iconName: 'material-symbols:globe-uk',
            style: 'background-color: rgb(5 150 105);',
        },
    ],
    shopLinks: [
        { href: 'https://www.akademiaszefowkuchni.pl', name: 'www.akademiaszefowkuchni.pl' },
        { href: 'mailto:wloskakademia@millesapori.pl', name: 'wloskakademia@millesapori.pl' },
    ],
    contact: [
        { heading: 'MILLE SAPORI PLUS Sp. z o.o.', content: '', translate: { heading: false, content: false } },
        { heading: '', content: 'ul. K.Gierdziejewskiego 7', translate: { heading: false, content: false } },
        { heading: '', content: '02-495 Warszawa', translate: { heading: false, content: false } },
        { heading: '', content: 'biuro@millesapori.pl', href: 'mailto:biuro@millesapori.pl', translate: { heading: false, content: false } },
        { heading: 'tel.', content: '+48 22 290 33 33', translate: { heading: false, content: false } },
        { heading: 'NIP:', content: 'PL 5272633791', translate: { heading: false, content: false } },
        { heading: 'REGON:', content: '142437942', translate: { heading: false, content: false } },
        {
            heading: 'KRS:',
            content: '0000361584 Krajowy Rejestr Sądowy Sąd Rejonowy dla m.st. Warszawy, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego',
            translate: { heading: false, content: false },
        },
        { heading: 'share-capital', content: '500.000,00 zł', translate: { heading: true, content: false } },
    ],
};
