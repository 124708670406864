<div class="fixed top-0 w-screen">
    <app-header></app-header>
</div>

<div class="min-h-screen grid">
    <section class="w-full overflow-auto h-full flex justify-center items-center">
        <div class="w-full">
            <router-outlet></router-outlet>
        </div>
    </section>
</div>
