import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConcessionsState } from '../reducers/concessions.reducer';
import { CONCESSIONS_FEATURE_KEY } from '../keys';
import { greaterThanTwoWeeks } from '../utils/greater-than-two-weeks';

const selectState = createFeatureSelector<ConcessionsState>(CONCESSIONS_FEATURE_KEY);

const selectPaymentEndsConcessions = createSelector(
    selectState,
    (state) =>
        state.concessions?.filter(
            (concession) => concession.enabled === true && concession.paymentEndDate !== null && greaterThanTwoWeeks(concession.paymentEndDate) === false,
        ) ?? [],
);

const selectAuthEndsConcessions = createSelector(
    selectState,
    (state) =>
        state.concessions?.filter(
            (concession) => concession.enabled === true && concession.authEndDate !== null && greaterThanTwoWeeks(concession.authEndDate) === false,
        ) ?? [],
);

export { selectPaymentEndsConcessions, selectAuthEndsConcessions };
