import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { VersionComponent } from '@app/modules/version';
import { options } from '@options';
import { FooterAdressesComponent } from './components/footer-adresses/footer-adresses.component';
import { FooterDefaultComponent } from './components/footer-default/footer-default.component';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: true,
    imports: [CommonModule, VersionComponent, FooterAdressesComponent, FooterDefaultComponent],
})
export class FooterComponent {
    readonly footerOverride = options.footer.override;
}
