import { ConcessionDto, ConcessionDtoKeys } from './concession.dto';

export class Concession {
    id: ReturnType<typeof getKey>;
    enabled: boolean;
    authEndDate: number | null;
    paymentEndDate: number | null;

    constructor(data: { key: ConcessionDtoKeys; concession: ConcessionDto }) {
        this.id = getKey(data.key);
        this.enabled = data.concession.excise;
        this.authEndDate = null;
        this.paymentEndDate = null;

        if (data.concession.auth_end_date !== '0D') {
            this.authEndDate = getTimestamp(data.concession.auth_end_date);
        }

        if (data.concession.payment_date !== '0D') {
            this.paymentEndDate = getTimestamp(data.concession.payment_date);
        }
    }
}

const getKey = (key: ConcessionDtoKeys) => {
    switch (key) {
        case 'wine_spirits_to18':
            return 'wine-spririts-to-18';
        default: {
            return key;
        }
    }
};

const getTimestamp = (data: string): number => {
    const [year, month, day] = data.split('-');
    const date = new Date(Number(year), Number(month) - 1, Number(day));
    return date.getTime() / 1000;
};
