import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Concession } from '../models';

export const concessionsActions = createActionGroup({
    source: 'Concessions',
    events: {
        'Get Concessions': emptyProps(),
        'Get Concessions Success': props<{ items: Concession[] }>(),
        'Get Concessions Error': emptyProps(),

        Clear: emptyProps(),
    },
});
