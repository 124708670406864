import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { IconsModule } from '@library';
import { ShellSharedModule } from '../../shared/shell-shared.module';
import { I18nModule } from '@app/i18n';
import { RouterModule } from '@angular/router';

import { ShellAuthComponent } from './components/shell/shell.component';
import { HeaderComponent } from './components/header/header.component';

@NgModule({
    imports: [CommonModule, IconsModule, ShellSharedModule, SharedModule, I18nModule, RouterModule],
    declarations: [ShellAuthComponent, HeaderComponent],
})
export class ShellAuthModule {}
