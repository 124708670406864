<ng-container *ngIf="paymentConcessions$ | async as concessions">
    <div
        *ngIf="concessions.length !== 0"
        @Grow
        (click)="toggle()"
        (keyup.enter)="toggle()"
        tabindex="0"
        class="grow box bottom-border rounded-2xl relative mb-5"
    >
        <span [ngClass]="(show$ | async) ? 'rotate-180' : ''" class="absolute right-[12px] top-[24px] transition">
            <app-icon name="ic:outline-keyboard-arrow-down" class="text-base"></app-icon>
        </span>
        <div class="text-base font-semibold text-gray-800 header">
            {{ 'concessions.payment-ends-title' | translate }}
        </div>

        <div *ngIf="show$ | async" @Grow>
            <ul class="ps-4 pb-2 pt-4">
                <li *ngFor="let concession of concessions" class="text-xs text-gray-800 font-medium">
                    {{ 'concessions.' + concession.id | translate }}
                </li>
            </ul>
            <div class="font-semibold text-sm">
                {{ 'concessions.description' | translate }}
            </div>
        </div>
    </div>
</ng-container>
