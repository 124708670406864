<div class="absolute bg-white shadow rounded-2xl border flex flex-col right-0 w-max p-6 gap-5 mt-2">
    <ng-container *ngIf="features$ | async as features">
        <a *ngIf="features.includes('complaints.list')" routerLink="/complaints">
            <app-icon name="hugeicons:package-remove" class="text-lg" />

            <span>
                {{ 'header.profile.popover.complaints' | translate }}
            </span>
        </a>

        <a [routerLink]="type === 'salesperson' ? '/profile/change-password' : '/user-profile/change-password'">
            <app-icon name="material-symbols:settings-outline" class="text-lg" />

            <span> {{ 'header.profile.popover.account-settings' | translate }}</span>
        </a>
    </ng-container>
</div>
