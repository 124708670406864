import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpService } from '../services/http.service';
import { concessionsActions } from '../actions/concessions.actions';
import { shippingAddressesActions } from '@app/store/shipping-addresses';

@Injectable()
export class ConcessionsEffects {
    private actions$ = inject(Actions);
    private httpService = inject(HttpService);

    reload$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(shippingAddressesActions.setAddressSuccess, shippingAddressesActions.getAddressesSuccess),
            map(() => concessionsActions.getConcessions()),
        );
    });

    getConcessions$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(concessionsActions.getConcessions),
            switchMap(() => {
                return this.httpService.getConcessions().pipe(
                    map((concessions) => concessionsActions.getConcessionsSuccess({ items: concessions })),
                    catchError(() => of(concessionsActions.getConcessionsError())),
                );
            }),
        );
    });
}
