import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagesComponent } from './components/messages/messages.component';
import { MessageComponent } from './components/message/message.component';
import { IconsModule } from '@library';
import { MessagesModule as ClMessagesModule } from '@corelabs/angular-messages';

@NgModule({
    imports: [CommonModule, IconsModule, ClMessagesModule.forRoot(5, 2, 100)],
    declarations: [MessagesComponent, MessageComponent],
    exports: [MessagesComponent],
})
export class MessagesModule {}
