import { Component, OnInit, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CleanupService } from './core/services/cleanup.service';
import { LoaderService } from './modules/loaders';
import { MessagesBusService } from './modules/messages';
import { LanguageService } from './i18n';
import { NavigationStart, Router } from '@angular/router';
import { delay, filter, tap } from 'rxjs';
import { WindowService } from '@library';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    // needs to be initiated
    readonly router = inject(Router);
    readonly windowService = inject(WindowService);
    readonly cleanupService = inject(CleanupService);
    readonly loaderService = inject(LoaderService);
    readonly messagesService = inject(MessagesBusService);
    readonly languageService = inject(LanguageService);
    readonly translateService = inject(TranslateService);
    readonly title = inject(Title);

    ngOnInit() {
        this.title.setTitle(this.translateService.instant('title'));

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationStart),
                delay(0),
                tap(() => this.windowService.scrollToTop()),
            )
            .subscribe();
    }
}
