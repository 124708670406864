<header class="border-b border-gray-100 w-full bg-white sticky top-0">
    <div class="mx-auto flex items-stretch">
        <div class="py-[1.625rem] px-8 sm:px-20 border-r border-gray-100">
            <a routerLink="dashboard">
                <img class="block min-w-[5rem] max-w-[5rem] sm:min-w-[7rem] sm:max-w-[7rem] w-21" [src]="logoSrc" alt="Logo" />
            </a>
        </div>

        <div class="ml-auto flex items-center">
            <div class="mr-6 sm:mr-8">
                <app-language-switch></app-language-switch>
            </div>
        </div>

        <div class="border-l border-gray-100 self-center py-6 px-8">
            <a class="btn btn--filled" routerLink="/auth/sign-in">{{ 'header.login' | translate }}</a>
        </div>
    </div>
</header>
