import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Concession, ConcessionsDto } from '../models';
import { ApiResponse } from '@app/shared/models';
import { ConcessionDtoKeys } from '../models/concession.dto';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private httpClient = inject(HttpClient);

    getConcessions(): Observable<Concession[]> {
        return this.httpClient
            .addAllSwitches()
            .get<ApiResponse<ConcessionsDto>>('api/v1/excises/customers/check')
            .pipe(
                map((res) =>
                    (Object.keys(res.data) as ConcessionDtoKeys[]).map(
                        (key) =>
                            new Concession({
                                key,
                                concession: res.data[key],
                            }),
                    ),
                ),
            );
    }
}
