import { Injectable, inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { constants } from '@const';
import { ShippingAddressesFacade } from '@app/store/shipping-addresses';

@Injectable({
    providedIn: 'root',
})
export class ShippingAddressSwitchInterceptor implements HttpInterceptor {
    private shippingAddressesFacade = inject(ShippingAddressesFacade);

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const key = constants.addressSwitch.keys.header;
        const value = this.shippingAddressesFacade.currentCode;
        if (value !== null) {
            const modifiedReq = req.clone({
                headers: req.headers.set(key, value),
            });
            return next.handle(modifiedReq);
        }
        return next.handle(req);
    }
}
