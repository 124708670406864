import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { footer } from '@footer';
import { IconsModule } from '@library';
import { TranslateModule } from '@ngx-translate/core';
import { options } from '@options';
import { SocialIconsComponent } from '../social-icons/social-icons.component';
import { UserFacade } from '@app/store/user';
import { map } from 'rxjs';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'app-footer-default',
    templateUrl: './footer-default.component.html',
    styleUrls: ['./footer-default.component.scss'],
    standalone: true,
    imports: [CommonModule, TranslateModule, IconsModule, RouterModule, SocialIconsComponent],
})
export class FooterDefaultComponent {
    private userFacade = inject(UserFacade);
    readonly shopLinks = footer.shopLinks;
    readonly contact = footer.contact;
    readonly features$ = this.userFacade.features$;
    readonly footerLinks$ = this.features$.pipe(map((features) => options.footerLinks.filter((e) => (e.feature ? features.includes(e.feature) : true))));
}
