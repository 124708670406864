import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { ConcessionsFacade } from '../facades/concessions.facade';

export function concessionsCleanup<T>(): CanDeactivateFn<T> {
    return () => {
        const concesssionsFacade = inject(ConcessionsFacade);
        concesssionsFacade.clear();
        return true;
    };
}
