<header>
    <div>
        <app-logo />
    </div>

    <div *ngIf="xl$ | async" class="menu">
        <app-menu [menu]="(menuItems$ | async) ?? []" />
    </div>

    <div class="ml-auto shipping">
        <app-shipping-addresses />
    </div>

    <div *ngIf="md$ | async">
        <app-language-switch />
    </div>

    <div *ngIf="md$ | async" class="cart">
        <app-cart-summary />
    </div>

    <div class="grid gap-x-8">
        <div *ngIf="md$ | async">
            <app-profile type="user" />
        </div>

        <div *ngIf="lg$ | async">
            <app-switch-user *ngIf="(userSwitchEnabled$ | async) === true" />
            <app-logout *ngIf="(userSwitchEnabled$ | async) === false" />
        </div>

        <div *ngIf="(xl$ | async) === false">
            <app-mobile-menu-button>
                <app-cart-summary-mobile />

                <app-menu-mobile [menu]="(menuItems$ | async) ?? []" />

                <div class="bottom-10 left-10 absolute">
                    <app-language-switch />
                </div>
            </app-mobile-menu-button>
        </div>
    </div>
</header>
