import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IconsModule } from '@library';
import { AuthConcessionsComponent } from './components/auth-concessions/auth-concessions.component';
import { PaymentConcessionsComponent } from './components/payment-concessions/payment-concessions.component';

@NgModule({
    declarations: [AuthConcessionsComponent, PaymentConcessionsComponent],
    imports: [CommonModule, TranslateModule, IconsModule],
    exports: [AuthConcessionsComponent, PaymentConcessionsComponent],
})
export class ConcessionsModule {}
