import { Component, inject } from '@angular/core';
import { map } from 'rxjs';
import { WindowService } from '@library';
import { UserSwitchService } from '@app/modules/user-switch';
import { UserFacade } from '@app/store/user';
import { Feature } from '@app/shared/types';
import { menu } from '../../configs/menu';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    private readonly userSwitchService = inject(UserSwitchService);
    private readonly windowService = inject(WindowService);
    private readonly userFacade = inject(UserFacade);

    readonly features$ = this.userFacade.features$;
    readonly menuItems$ = this.features$.pipe(map((features) => this.getMenuItems(features)));

    xl$ = this.windowService.xl$;
    lg$ = this.windowService.lg$;
    md$ = this.windowService.md$;
    sm$ = this.windowService.sm$;
    xs$ = this.windowService.xs$;

    userSwitchEnabled$ = this.userSwitchService.enabled$;

    private getMenuItems(features: Feature[]) {
        return menu.filter((column) => column.feature && features.includes(column.feature));
    }
}
