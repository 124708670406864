import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { concessionsActions } from '../actions/concessions.actions';
import { selectAuthEndsConcessions, selectPaymentEndsConcessions } from '../selectors/concessions.selector';

@Injectable({
    providedIn: 'root',
})
export class ConcessionsFacade {
    private readonly store = inject(Store);

    paymentEndsConcessions$ = this.store.select(selectPaymentEndsConcessions);
    authEndsConcessions$ = this.store.select(selectAuthEndsConcessions);

    getConcessions(): void {
        this.store.dispatch(concessionsActions.getConcessions());
    }

    clear(): void {
        this.store.dispatch(concessionsActions.clear());
    }
}
